(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/products-selector/assets/javascripts/products-filter.js') >= 0) return;  svs.modules.push('/widgets/products-selector/assets/javascripts/products-filter.js');
'use strict';

var _svs$widgets;
svs = svs || {};
svs.widget = svs.widget || {};
svs.widget.productsSelector = svs.widget.productsSelector || {};
const {
  helpers
} = svs.utils.products;
const {
  getDisplayName,
  resolveProductId
} = svs.components.lbUtils.sportProducts;
const {
  links,
  categories,
  sortedDefaultProducts
} = ((_svs$widgets = svs.widgets) === null || _svs$widgets === void 0 || (_svs$widgets = _svs$widgets.productsSelector) === null || _svs$widgets === void 0 || (_svs$widgets = _svs$widgets.data) === null || _svs$widgets === void 0 ? void 0 : _svs$widgets.categoriesAndLinks) || {};
const _replaceSweChars = stringToFix => stringToFix.replace('å', 'a').replace('Å', 'A').replace('ä', 'a').replace('Ä', 'A').replace('ö', 'o').replace('Ö', 'O');
const _isOddsenProduct = product => helpers.isOddset(product) || helpers.isPowerplay(product) || helpers.isBomben(product) || helpers.isChallenge(product) || helpers.isMatchen(product);
const _mapProductNames = products => products.map(_ref => {
  let {
    identifier
  } = _ref;
  return getDisplayName(identifier);
});
const _filterProductLinks = () => {
  const selectedCategories = categories.filter(_ref2 => {
    let {
      isSelected
    } = _ref2;
    return isSelected;
  });
  const noneOrAllSelected = !selectedCategories.length || selectedCategories.length === categories.length;
  if (noneOrAllSelected) {
    return links;
  }
  return links.filter(_ref3 => {
    let {
      productCategories
    } = _ref3;
    return productCategories.some(_ref4 => {
      let {
        name
      } = _ref4;
      return selectedCategories.some(_ref5 => {
        let {
          name: selectedCategoryName
        } = _ref5;
        return name === selectedCategoryName;
      });
    });
  });
};
const _setCategorySelection = productCategoryElement => {
  const category = categories.find(_ref6 => {
    let {
      name
    } = _ref6;
    return name === productCategoryElement.textContent;
  });
  category.isSelected = !category.isSelected;
  if (category.isSelected) {
    productCategoryElement.classList.add('btn-inverted');
    productCategoryElement.classList.remove('btn-transparent-default');
  } else {
    productCategoryElement.classList.add('btn-transparent-default');
    productCategoryElement.classList.remove('btn-inverted');
  }
};
const _filterProducts = () => {
  const selectedCategories = categories.filter(_ref7 => {
    let {
      isSelected
    } = _ref7;
    return isSelected;
  });
  let orderedProducts;
  if (!selectedCategories.length || selectedCategories.length === categories.length) {
    orderedProducts = sortedDefaultProducts.flat();
  } else {
    const selectedCategoriesProducts = selectedCategories.map(_ref8 => {
      let {
        products
      } = _ref8;
      return _mapProductNames(products);
    }).flat();
    const nonOddsenProducts = selectedCategoriesProducts.filter(product => !_isOddsenProduct(product));
    const oddsenProducts = sortedDefaultProducts.reduce((accumulator, product) => {
      if (_isOddsenProduct(product) && selectedCategoriesProducts.find(selectedCategoryProduct => selectedCategoryProduct === product)) {
        return [...accumulator, product];
      }
      return accumulator;
    }, []);
    orderedProducts = [...nonOddsenProducts, ...oddsenProducts];
  }
  const productItemsGrid = document.querySelector('.product-items-grid');
  const orderedProductElements = orderedProducts.map(product => [...productItemsGrid.children].find(element => parseInt(element.id, 10) === resolveProductId(product)));
  const allProductElements = [...new Set(orderedProductElements.concat([...productItemsGrid.children]))];
  const productLinks = _filterProductLinks();
  for (let i = productItemsGrid.children.length - 1; i > -1; i--) {
    productItemsGrid.children[i].parentNode.removeChild(productItemsGrid.children[i]);
  }
  allProductElements.forEach(productElement => {
    if (!productElement) {
      return;
    }
    if (!productLinks.some(_ref9 => {
      let {
        productId
      } = _ref9;
      return parseInt(productElement.id, 10) === productId;
    })) {
      productElement.classList.add('hidden');
    } else {
      productElement.classList.remove('hidden');
    }
    productItemsGrid.appendChild(productElement);
  });
};
const handleClickCategory = clickEvent => {
  clickEvent.preventDefault();
  const productCategoryElement = clickEvent.currentTarget;
  if (!productCategoryElement) {
    return;
  }
  _setCategorySelection(productCategoryElement);
  _filterProducts();
  const event = {
    action: 'Game Chooser-2',
    category: 'Navigation',
    opt_label: "filter-".concat(String(productCategoryElement.textContent).toLowerCase())
  };
  svs.components.analytics.trackEvent(event);
  const selectedCategories = categories.reduce((accumulator, category) => {
    if (category.isSelected) {
      return [...accumulator, _replaceSweChars(category.name).toLowerCase()];
    }
    return accumulator;
  }, []);
  const url = new URL(location);
  if (selectedCategories.length) {
    url.searchParams.set('categories', selectedCategories);
  } else {
    url.searchParams.delete('categories');
  }
  history.pushState({}, '', url);
};
setGlobal('svs.widget.productsSelector.productsFilter', {
  handleClickCategory
});

 })(window);