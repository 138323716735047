(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/products-selector/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/widgets/products-selector/assets/javascripts/init.js');
"use strict";


svs = svs || {};
svs.widget = svs.widget || {};
svs.widget.productsSelector = svs.widget.productsSelector || {};
const allWidgets = document.querySelectorAll('.js-widget-products-selector');
allWidgets.forEach(widgetEl => {
  if (widgetEl.dataset.init === 'done') {
    return;
  }
  widgetEl.dataset.init = 'done';
  const productElements = widgetEl.querySelectorAll('.js-products');
  productElements.forEach(el => {
    el.addEventListener('click', svs.widget.productsSelector.handleClickProduct);
  });
  const categoryElements = widgetEl.querySelectorAll('.js-product-category');
  categoryElements.forEach(el => {
    el.addEventListener('click', svs.widget.productsSelector.productsFilter.handleClickCategory);
  });
});

 })(window);