(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/products-selector/assets/javascripts/products-selector.js') >= 0) return;  svs.modules.push('/widgets/products-selector/assets/javascripts/products-selector.js');
'use strict';

const handleClickProduct = clickEvent => {
  clickEvent.preventDefault();
  const clickedEl = clickEvent.currentTarget;
  const productNameEl = clickedEl.querySelector('.product-info-name');
  if (!productNameEl) {
    return;
  }
  const productName = productNameEl.textContent;
  const event = {
    action: 'Game Chooser-2',
    category: 'Navigation',
    opt_label: productName
  };
  const url = clickedEl.getAttribute('href');
  svs.components.analytics.trackEvent(event, () => {
    location.href = url;
  });
};
setGlobal('svs.widget.productsSelector.handleClickProduct', handleClickProduct);

 })(window);